.IntroModal {
  padding: 24px 32px;

  &__Top {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-bottom: 36px;

    &--Icon {
      margin-bottom: 20px;
    }

    &--Heading {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      text-align: center;

      /* Black */

      color: rgba(0, 0, 0, 0.87);
    }
  }

  &__Body {
    &__Rules {
      margin-bottom: 16px;
      &--Rule {
        margin-bottom: 24px;

        display: flex;
        align-items: center;

        &--Bullet {
          font-style: normal;
          font-weight: bold;
          font-size: 32px;
          line-height: 38px;

          color: rgba(31, 51, 107, 0.4);

          margin-right: 16px;
        }

        &--Text {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          /* or 143% */

          /* Black */

          color: rgba(0, 0, 0, 0.87);
        }
      }
    }

    &__Actions {
      display: flex;
      flex-direction: column;
      align-items: center;

      &--GotIt {
        margin-bottom: 16px;
      }
    }
  }
}
