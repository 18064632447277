@import url('https://fonts.cdnfonts.com/css/circular-std-book');

* {
  font-family: 'Circular Std Book', sans-serif;
  box-sizing: border-box;
  transition: transform 0.3s ease-in;
  scroll-behavior: smooth;
}
body {
  margin: 0;
  padding: 0;
}
