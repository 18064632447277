@import url(https://fonts.cdnfonts.com/css/circular-std-book);
*{font-family:'Circular Std Book', sans-serif;box-sizing:border-box;transition:-webkit-transform 0.3s ease-in;transition:transform 0.3s ease-in;transition:transform 0.3s ease-in, -webkit-transform 0.3s ease-in;scroll-behavior:smooth}body{margin:0;padding:0}

.StudentHome{margin:56px 0 0;padding:16px 16px 0}.StudentHome__Top{margin-bottom:50px}

.Navbar{position:fixed;left:0;top:0;width:100vw;z-index:1000;padding:16px;background-color:#009ae0;display:flex;justify-content:space-between;align-items:center}.Navbar__Left{display:flex;align-items:center}.Navbar__Left--Heading{font-style:normal;font-weight:500;font-size:20px;line-height:24px;color:#ffffff}.Navbar__Left img{margin-right:24px;width:24px}

.Heading{font-style:normal;font-weight:500;font-size:16px;line-height:24px;display:flex;align-items:center;color:rgba(0,0,0,0.87)}.SubHeading{font-style:normal;font-weight:normal;font-size:14px;line-height:20px;display:flex;align-items:center;color:#666666}

.Banner{margin-bottom:16px}.Banner__Top img{width:100%;margin-bottom:32px}.Banner__Top__Description{display:flex;flex-direction:column;align-items:center}.Banner__Top__Description .Heading{margin-bottom:8px;text-align:center}.Banner__Top__Description .SubHeading{text-align:center}.Coupon{margin-bottom:16px;border:1px dashed #c4c4c4;box-sizing:border-box;border-radius:4px;padding:9px 0;display:flex;justify-content:space-between;align-items:center}.Coupon:active{background-color:#efefef}.Coupon--Code{resize:none;border:none;outline:none;padding:0 20px;background-color:transparent;text-transform:uppercase}.Coupon--Code::selection{background-color:transparent}.Coupon--Copy{border-left:1px solid #e5e5e5;padding:0 20px}.CouponActions{display:flex;width:100%;grid-gap:8px}.CouponActions--Whatsapp{width:100%;padding:12px 0}.CouponActions--Whatsapp div{display:flex;align-items:center}.CouponActions--Whatsapp div img{margin-right:8px}.CouponActions--Whatsapp div span{white-space:nowrap}

.Button{padding:12px 48px;border:1px solid #009ae0;font-style:normal;font-weight:500;font-size:16px;line-height:24px;display:flex;align-items:center;justify-content:center;text-align:center;color:#ffffff;border-radius:4px}.Button:disabled{background:#ffffff !important;border:1px solid #e5e5e5 !important;box-sizing:border-box !important;border-radius:4px !important;color:#666666 !important}

.Loader{width:100%;display:flex;justify-content:center;align-items:center;margin-top:40px}.lds-ellipsis{display:inline-block;position:relative;width:80px;height:80px}.lds-ellipsis div{position:absolute;top:33px;width:13px;height:13px;border-radius:50%;background:#009ae0;-webkit-animation-timing-function:cubic-bezier(0, 1, 1, 0);animation-timing-function:cubic-bezier(0, 1, 1, 0)}.lds-ellipsis div:nth-child(1){left:8px;-webkit-animation:lds-ellipsis1 0.6s infinite;animation:lds-ellipsis1 0.6s infinite}.lds-ellipsis div:nth-child(2){left:8px;-webkit-animation:lds-ellipsis2 0.6s infinite;animation:lds-ellipsis2 0.6s infinite}.lds-ellipsis div:nth-child(3){left:32px;-webkit-animation:lds-ellipsis2 0.6s infinite;animation:lds-ellipsis2 0.6s infinite}.lds-ellipsis div:nth-child(4){left:56px;-webkit-animation:lds-ellipsis3 0.6s infinite;animation:lds-ellipsis3 0.6s infinite}@-webkit-keyframes lds-ellipsis1{0%{-webkit-transform:scale(0);transform:scale(0)}100%{-webkit-transform:scale(1);transform:scale(1)}}@keyframes lds-ellipsis1{0%{-webkit-transform:scale(0);transform:scale(0)}100%{-webkit-transform:scale(1);transform:scale(1)}}@-webkit-keyframes lds-ellipsis3{0%{-webkit-transform:scale(1);transform:scale(1)}100%{-webkit-transform:scale(0);transform:scale(0)}}@keyframes lds-ellipsis3{0%{-webkit-transform:scale(1);transform:scale(1)}100%{-webkit-transform:scale(0);transform:scale(0)}}@-webkit-keyframes lds-ellipsis2{0%{-webkit-transform:translate(0, 0);transform:translate(0, 0)}100%{-webkit-transform:translate(24px, 0);transform:translate(24px, 0)}}@keyframes lds-ellipsis2{0%{-webkit-transform:translate(0, 0);transform:translate(0, 0)}100%{-webkit-transform:translate(24px, 0);transform:translate(24px, 0)}}

.Notification{position:fixed;top:56px;left:0;width:100vw;display:flex;justify-content:center;align-items:center;padding:14px 0;width:100vw;margin-bottom:16px;background-color:#fab73b;font-style:normal;font-weight:500;font-size:14px;line-height:20px;color:rgba(0,0,0,0.87)}

.RewardEntry{display:flex;justify-content:space-between;align-items:center;padding:12px 16px;border:1px solid #e5e5e5;border-radius:4px;margin-bottom:16px}.RewardEntry__Right{display:flex;align-items:center}.RewardEntry__Right img{margin-left:12px}.Overview{margin-bottom:24px}.Overview__Top{margin-bottom:8px}.Overview__Body{display:grid;grid-template-columns:1fr 1fr;grid-gap:8px}.ReferrerList__Top{margin-bottom:16px}.NoNewStudents{display:flex;flex-direction:column;align-items:center;padding:24px 16px 0}.NoNewStudents img{margin-bottom:22px}.NoNewStudents .Heading{margin-bottom:4px;text-align:center}.NoNewStudents .SubHeading{text-align:center}

.OverviewCard{border:1px solid #e5e5e5;box-sizing:border-box;border-radius:8px;overflow:hidden;padding:16px;position:relative}.OverviewCard img{position:absolute;top:0;right:0}.OverviewCard__Text--Value{font-style:normal;font-weight:600;font-size:20px;line-height:24px;color:rgba(0,0,0,0.87);margin-bottom:7px}.OverviewCard__Text--Label{font-style:normal;font-weight:normal;font-size:12px;line-height:14px;color:#666666}

.ReferrerCard{display:flex;justify-content:space-between;align-items:center;margin:16px 0 0}.ReferrerCard:last-child{margin:16px 0}.ReferrerCard__Referrer{display:flex;align-items:center}.ReferrerCard__Referrer--Profile{width:40px;height:40px;border-radius:50%;overflow:hidden;margin-right:16px}.ReferrerCard__Referrer--Profile img{width:100%}.ReferrerCard__Referrer--Details .Heading{text-transform:capitalize;margin-bottom:3px}

.ProfilePlaceholder{color:#ffffff;display:flex;align-items:center;justify-content:center;width:100%;height:100%;text-transform:uppercase;font-size:20px}

.Toast{font-style:normal;font-weight:normal;font-size:14px;line-height:24px;display:flex;align-items:center;justify-content:center;text-align:center;color:rgba(0,0,0,0.87);padding:12px;width:90vw;background:#e5e5e5;border-radius:240px;position:fixed;bottom:120px;left:5vw}

.TutorHome{margin:56px 0 0;padding:16px 16px 0}.TutorHome__Top{border-bottom:1px solid #e5e5e5;margin-bottom:16px}.TutorHome__Body .infinite-scroll-component{height:calc(100vh - 155px) !important}

.IntroModal{padding:24px 32px}.IntroModal__Top{display:flex;flex-direction:column;align-items:center;margin-bottom:36px}.IntroModal__Top--Icon{margin-bottom:20px}.IntroModal__Top--Heading{font-style:normal;font-weight:600;font-size:20px;line-height:24px;text-align:center;color:rgba(0,0,0,0.87)}.IntroModal__Body__Rules{margin-bottom:16px}.IntroModal__Body__Rules--Rule{margin-bottom:24px;display:flex;align-items:center}.IntroModal__Body__Rules--Rule--Bullet{font-style:normal;font-weight:bold;font-size:32px;line-height:38px;color:rgba(31,51,107,0.4);margin-right:16px}.IntroModal__Body__Rules--Rule--Text{font-style:normal;font-weight:normal;font-size:14px;line-height:20px;color:rgba(0,0,0,0.87)}.IntroModal__Body__Actions{display:flex;flex-direction:column;align-items:center}.IntroModal__Body__Actions--GotIt{margin-bottom:16px}

.ModalWrapper{position:fixed;left:0;top:0;width:100vw;height:100vh;display:flex;justify-content:center;align-items:flex-end;transition:opacity 0.2s ease-in, z-index 0.2s ease-in}.ModalWrapper__Show{z-index:100;opacity:1}.ModalWrapper__Hide{z-index:-1;opacity:0}.Backdrop{background-color:rgba(0,0,0,0.6);position:fixed;width:100vw;height:100vh;left:0;top:0;transition:display 0.2s ease-in}.Backdrop--Show{display:block}.Backdrop--Hide{display:none}.Modal{width:100vw;background-color:#ffffff;position:absolute;transition:bottom 0.5s ease-in;border-radius:10px 10px 0px 0px;overflow:hidden}.Modal__Show{position:absolute;bottom:0}.Modal__Hide{position:absolute;bottom:-100vw}

.RefereeModal__Top{display:flex;justify-content:space-between;align-items:center;padding:16px;border-bottom:1px solid #efefef}.RefereeModal__Top .Heading{text-transform:capitalize}.RefereeModal__Top--Close{width:24px}.RefereeModal__Top--Close img{width:100%}.RefereeModal__Body{padding-left:16px}

.FAQPage{margin:56px 0 90px;padding:16px}

.Dropdown{border-bottom:1px solid #e5e5e5;padding:12px 0}.Dropdown:last-child{border-bottom:none}.Dropdown__Main{display:flex;justify-content:space-between;align-items:flex-start}.Dropdown__Main--Heading{font-style:normal;font-weight:600;font-size:14px;line-height:24px;color:#666666}.Dropdown__Main--Toggle{position:relative;display:flex;justify-content:center;align-items:center;min-width:24px;min-height:24px;max-width:24px;max-height:24px;transition:background-color 0.5s ease-in;border-radius:50%}.Dropdown__Main--Toggle--Verticle{position:absolute}.Dropdown__Main--Toggle--Verticle--Hide{-webkit-transform:rotate(-270deg);transform:rotate(-270deg)}.Dropdown__Main--Toggle--Show{background-color:#e5e5e5}.Dropdown__Extended{overflow:hidden}.Dropdown__Extended--Hide{max-height:0;transition:padding-top 0.5s cubic-bezier(0, 1, 0, 1),max-height 0.5s cubic-bezier(0, 1, 0, 1)}.Dropdown__Extended--Show{max-height:1000px;transition:padding-top 0.3s ease-in-out, max-height 1s ease-in-out;padding-top:8px}

.EditPage{margin:56px 0 90px}.EditPage__Body{padding:16px}.EditPage__Body__Section{padding:14px;border:1px solid #e5e5e5;box-sizing:border-box;box-shadow:0px 2px 4px rgba(229,229,229,0.4);border-radius:8px;margin-bottom:16px}.EditPage__Body__Section:last-child{margin-bottom:0}.EditPage__Body__Section__Top{margin-bottom:16px}.EditPage__Body__Section__Inputs{display:grid;grid-template-columns:1fr 1fr;grid-gap:14px;margin-bottom:16px}.EditPage__Body__Section__Inputs:last-child{margin-bottom:0}

.Footer{position:fixed;bottom:0;left:0;padding:16px;border-top:1px solid #e5e5e5;background-color:#ffffff;width:100vw}

.Input .SubHeading{margin-bottom:8px}.Input__Field{border:1px solid #e5e5e5;box-sizing:border-box;border-radius:4px;outline:none;width:100%;font-style:normal;font-weight:normal;font-size:16px;line-height:24px;display:flex;align-items:center;color:rgba(0,0,0,0.87);padding:12px 16px}.Input__Field::-webkit-input-placeholder{color:#666666}.Input__Field:-ms-input-placeholder{color:#666666}.Input__Field::placeholder{color:#666666}

.FAQPage{margin:56px 0 90px;padding:16px}

.Rewards{margin:56px 0 90px}

.RewardSection{padding:16px;border-bottom:8px solid #e5e5e5}.RewardSection__Top{display:flex;justify-content:space-between;align-items:center;margin-bottom:12px}.RewardSection__Body__Action--Edit div{display:flex;align-items:center}.RewardSection__Body__Action--Edit div img{margin-right:8px}.FAQSection{padding:16px}

.RewardCard{border:1px solid #e5e5e5;box-sizing:border-box;box-shadow:0px 2px 4px rgba(229,229,229,0.4);border-radius:8px;padding:16px;margin-bottom:16px}.RewardCard__Label{margin-bottom:16px}.RewardCard__Reward{display:flex;align-items:center}.RewardCard__Reward__Icon{margin-right:12px;width:48px}.RewardCard__Reward__Icon img{width:100%}

.Prompt__Icon{margin-bottom:12px}.Prompt__Body{display:flex;flex-direction:column;align-items:center;margin-bottom:32px}.Success{display:flex;flex-direction:column;align-items:center}.Success img{width:100%}.Success .Heading{display:flex;justify-content:center;text-align:center}

.PopupWrapper{position:fixed;left:0;top:0;width:100vw;height:100vh;display:grid;place-content:center;transition:opacity 0.2s ease-in, z-index 0.2s ease-in}.PopupWrapper__Show{z-index:100;opacity:1}.PopupWrapper__Hide{z-index:-1;opacity:0}.Backdrop{background-color:rgba(0,0,0,0.6);position:fixed;width:100vw;height:100vh;left:0;top:0;transition:display 0.2s ease-in}.Backdrop--Show{z-index:100;display:block}.Backdrop--Hide{z-index:-1;display:none}.Popup{background-color:#ffffff;position:absolute;width:90vw;left:5vw;padding:24px;border-radius:4px;transition:top 0.5s ease-in;display:flex;flex-direction:column;justify-content:center;align-items:center}.Popup__Show{top:50vw}.Popup__Hide{top:-100vw}

