.ProfilePlaceholder {
  color: #ffffff;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  text-transform: uppercase;

  font-size: 20px;
}
