.OverviewCard {
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 8px;

  overflow: hidden;

  padding: 16px;

  position: relative;

  img {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__Text {
    &--Value {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      /* identical to box height */

      /* Black */

      color: rgba(0, 0, 0, 0.87);

      margin-bottom: 7px;
    }

    &--Label {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;

      /* Sub-Text */

      color: #666666;
    }
  }
}
