.PopupWrapper {
  position: fixed;
  left: 0;
  top: 0;

  width: 100vw;
  height: 100vh;

  display: grid;
  place-content: center;
  // justify-content: center;
  // align-items: flex-end;

  transition: opacity 0.2s ease-in, z-index 0.2s ease-in;

  &__Show {
    z-index: 100;
    opacity: 1;
  }

  &__Hide {
    z-index: -1;
    opacity: 0;
  }
}

.Backdrop {
  background-color: rgba($color: #000000, $alpha: 0.6);

  position: fixed;
  width: 100vw;
  height: 100vh;

  left: 0;
  top: 0;

  transition: display 0.2s ease-in;

  &--Show {
    z-index: 100;
    display: block;
  }

  &--Hide {
    z-index: -1;
    display: none;
  }
}

.Popup {
  background-color: #ffffff;
  position: absolute;

  width: 90vw;
  left: 5vw;

  padding: 24px;
  border-radius: 4px;

  transition: top 0.5s ease-in;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__Show {
    top: 50vw;
  }

  &__Hide {
    top: -100vw;
  }
}
