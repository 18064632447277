.Prompt {
  &__Icon {
    margin-bottom: 12px;
  }

  &__Body {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-bottom: 32px;
  }
}

.Success {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 100%;
  }

  .Heading {
    display: flex;
    justify-content: center;
    text-align: center;
  }
}
