.ReferrerCard {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 16px 0 0;

  &:last-child {
    margin: 16px 0;
  }

  &__Referrer {
    display: flex;
    align-items: center;

    &--Profile {
      width: 40px;
      height: 40px;

      border-radius: 50%;

      overflow: hidden;

      margin-right: 16px;

      img {
        width: 100%;
      }
    }

    &--Details {
      .Heading {
        text-transform: capitalize;

        margin-bottom: 3px;
      }
    }
  }
}
