.Banner {
  margin-bottom: 16px;
  &__Top {
    img {
      width: 100%;

      margin-bottom: 32px;
    }

    &__Description {
      display: flex;
      flex-direction: column;
      align-items: center;
      .Heading {
        margin-bottom: 8px;

        text-align: center;
      }

      .SubHeading {
        text-align: center;
      }
    }
  }
}

.Coupon {
  margin-bottom: 16px;

  border: 1px dashed #c4c4c4;
  box-sizing: border-box;
  border-radius: 4px;

  padding: 9px 0;

  display: flex;
  justify-content: space-between;
  align-items: center;

  &:active {
    background-color: #efefef;
  }

  &--Code {
    resize: none;
    border: none;
    outline: none;

    padding: 0 20px;

    background-color: transparent;

    text-transform: uppercase;

    &::selection {
      background-color: transparent;
    }
  }

  &--Copy {
    border-left: 1px solid #e5e5e5;
    padding: 0 20px;
  }
}

.CouponActions {
  display: flex;
  width: 100%;
  grid-gap: 8px;

  &--Whatsapp {
    width: 100%;

    padding: 12px 0;

    div {
      display: flex;
      align-items: center;

      img {
        margin-right: 8px;
      }

      span {
        white-space: nowrap;
      }
    }
  }
}
