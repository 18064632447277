.RewardSection {
  padding: 16px;

  border-bottom: 8px solid #e5e5e5;

  &__Top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 12px;
  }

  &__Body {
    &__Action {
      &--Edit {
        div {
          display: flex;
          align-items: center;

          img {
            margin-right: 8px;
          }
        }
      }
    }
  }
}

.FAQSection {
  padding: 16px;
}
