.RewardCard {
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(229, 229, 229, 0.4);
  border-radius: 8px;

  padding: 16px;

  margin-bottom: 16px;

  &__Label {
    margin-bottom: 16px;
  }

  &__Reward {
    display: flex;
    align-items: center;

    &__Icon {
      margin-right: 12px;

      width: 48px;

      img {
        width: 100%;
      }
    }

    &__Details {
    }
  }
}
