.RefereeModal {
  &__Top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 16px;

    border-bottom: 1px solid #efefef;

    .Heading {
      text-transform: capitalize;
    }

    &--Close {
      width: 24px;

      img {
        width: 100%;
      }
    }
  }

  &__Body {
    padding-left: 16px;
  }
}
