.Dropdown {
  border-bottom: 1px solid #e5e5e5;

  padding: 12px 0;

  &:last-child {
    border-bottom: none;
  }

  &__Main {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &--Heading {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      /* identical to box height, or 171% */

      /* Sub-Text */

      color: #666666;
    }

    &--Toggle {
      position: relative;

      display: flex;
      justify-content: center;
      align-items: center;

      min-width: 24px;
      min-height: 24px;
      max-width: 24px;
      max-height: 24px;

      transition: background-color 0.5s ease-in;

      border-radius: 50%;

      &--Verticle {
        position: absolute;

        &--Hide {
          transform: rotate(-270deg);
        }
      }

      &--Show {
        background-color: #e5e5e5;
      }
    }
  }

  &__Extended {
    // transition: margin-top 0.5s ease-in,
    //   max-height 0.5s cubic-bezier(0, 1, 0, 1);

    overflow: hidden;

    &--Hide {
      max-height: 0;
      transition: padding-top 0.5s cubic-bezier(0, 1, 0, 1),
        max-height 0.5s cubic-bezier(0, 1, 0, 1);
    }

    &--Show {
      max-height: 1000px;
      transition: padding-top 0.3s ease-in-out, max-height 1s ease-in-out;

      padding-top: 8px;
    }
  }
}
