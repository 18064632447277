.Input {
  .SubHeading {
    margin-bottom: 8px;
  }

  &__Field {
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 4px;

    outline: none;

    width: 100%;

    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    display: flex;
    align-items: center;

    /* Black */

    color: rgba(0, 0, 0, 0.87);

    padding: 12px 16px;

    &::placeholder {
      color: #666666;
    }
  }
}
