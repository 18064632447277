.Navbar {
  position: fixed;
  left: 0;
  top: 0;

  width: 100vw;

  z-index: 1000;

  padding: 16px;

  background-color: #009ae0;

  display: flex;
  justify-content: space-between;
  align-items: center;

  &__Left {
    display: flex;
    align-items: center;

    &--Heading {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      /* identical to box height, or 120% */

      /* 03. On Primary / High Emphasis */

      color: #ffffff;
    }

    img {
      margin-right: 24px;
      width: 24px;
    }
  }
  &__Right {
  }
}
