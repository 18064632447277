.Footer {
  position: fixed;
  bottom: 0;
  left: 0;

  padding: 16px;

  border-top: 1px solid #e5e5e5;
  background-color: #ffffff;

  width: 100vw;
}
