.EditPage {
  margin: 56px 0 90px;

  &__Body {
    padding: 16px;

    &__Section {
      padding: 14px;

      border: 1px solid #e5e5e5;
      box-sizing: border-box;
      box-shadow: 0px 2px 4px rgba(229, 229, 229, 0.4);
      border-radius: 8px;

      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }

      &__Top {
        margin-bottom: 16px;
      }

      &__Inputs {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 14px;

        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
