.ModalWrapper {
  position: fixed;
  left: 0;
  top: 0;

  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: flex-end;

  transition: opacity 0.2s ease-in, z-index 0.2s ease-in;

  &__Show {
    z-index: 100;
    opacity: 1;
  }

  &__Hide {
    z-index: -1;
    opacity: 0;
  }
}

.Backdrop {
  background-color: rgba($color: #000000, $alpha: 0.6);

  position: fixed;
  width: 100vw;
  height: 100vh;

  left: 0;
  top: 0;

  transition: display 0.2s ease-in;

  &--Show {
    // z-index: 100;
    display: block;
  }

  &--Hide {
    // z-index: -1;
    display: none;
  }
}

.Modal {
  width: 100vw;
  background-color: #ffffff;

  position: absolute;

  transition: bottom 0.5s ease-in;

  border-radius: 10px 10px 0px 0px;
  overflow: hidden;

  &__Show {
    position: absolute;
    bottom: 0;
  }

  &__Hide {
    position: absolute;
    bottom: -100vw;
  }
}
