.RewardEntry {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 12px 16px;

  border: 1px solid #e5e5e5;
  border-radius: 4px;

  margin-bottom: 16px;

  &__Left {
  }

  &__Right {
    display: flex;
    align-items: center;

    img {
      margin-left: 12px;
    }
  }
}

.Overview {
  margin-bottom: 24px;

  &__Top {
    margin-bottom: 8px;
  }

  &__Body {
    display: grid;
    grid-template-columns: 1fr 1fr;

    grid-gap: 8px;
  }
}

.ReferrerList {
  &__Top {
    margin-bottom: 16px;
  }
}

.NoNewStudents {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 24px 16px 0;

  img {
    margin-bottom: 22px;
  }

  .Heading {
    margin-bottom: 4px;
    text-align: center;
  }

  .SubHeading {
    text-align: center;
  }
}
