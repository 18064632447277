.Button {
  padding: 12px 48px;

  border: 1px solid #009ae0;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  color: #ffffff;

  border-radius: 4px;

  &:disabled {
    background: #ffffff !important;
    /* Divider */

    border: 1px solid #e5e5e5 !important;
    box-sizing: border-box !important;
    border-radius: 4px !important;

    color: #666666 !important;
  }
}
