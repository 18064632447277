.TutorHome {
  margin: 56px 0 0;

  padding: 16px 16px 0;

  &__Top {
    border-bottom: 1px solid #e5e5e5;

    margin-bottom: 16px;
  }

  &__Body {
    .infinite-scroll-component {
      height: calc(100vh - 155px) !important;
    }
  }
}
